<template>
  <v-container fluid class="record-container pa-4 pa-sm-6 pa-md-8">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <v-card elevation="2" class="mb-6">
          <!-- 头像和标题行 -->
          <v-card-title class="title-row">
            <v-avatar size="100" class="mr-4">
              <v-img :src="getImageUrl(record.title)" class="record-avatar" contain></v-img>
            </v-avatar>
            <div>
              <h1 class="text-h4 font-weight-bold mb-2">{{ record.title }}</h1>
              <v-chip v-for="category in categories" :key="category" class="mr-2 mb-2" small outlined>
                {{ category }}
              </v-chip>
            </div>
          </v-card-title>
          <v-card-actions v-if="!record.personId">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="editRecord" text>
              <v-icon left>mdi-pencil</v-icon>
              编辑条目
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="content-card" elevation="2">
          <v-card-text>
            <!-- 使用 v-html 渲染内容 -->
            <div class="record-content" v-html="record.content"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 提交反馈提示 -->
    <v-snackbar v-model="snackbar" color="success">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
  name: 'Record',
  props: ['id'],
  data() {
    return {
      record: {
        personId: 100000000,
        title: '',
        content: ''
      },
      categories: [],
      snackbar: false,
      snackbarText: ''
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  created() {
    this.fetchRecord();
    this.fetchCategories();
  },
  methods: {
    fetchRecord() {
      service
        .get(`/wikiapi/record`, { params: { id: this.id } })
        .then(response => {
          this.record = response.data;
        })
        .catch(error => {
          console.error('Error fetching record:', error);
          this.snackbarText = '加载条目失败，请重试。';
          this.snackbar = true;
        });
    },
    fetchCategories() {
      service
        .get(`/wikiapi/record/categories`, { params: { id: this.id } })
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    },
    getImageUrl(title) {
      const encodedTitle = encodeURIComponent(title);
      return `https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/${encodedTitle}.jpg`;
    },
    editRecord() {
      this.$router.push({ name: '编辑条目', params: { id: this.record.id } });
    }
  }
};
</script>

<style scoped>
.record-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px);
}

.title-row {
  display: flex;
  align-items: center;
  padding: 16px;
}

.record-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.content-card {
  background-color: #ffffff;
}

.record-content {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
}

/* 使用 ::v-deep 选择器来确保样式作用于渲染的 HTML 内容 */
.record-content ::v-deep h3 {
  font-size: 1.5em;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #444;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.record-content ::v-deep p {
  margin-bottom: 16px;
}

/* 修改表格相关样式 */
.record-content ::v-deep table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
  table-layout: fixed; /* 添加这一行以确保列宽设置生效 */
}

.record-content ::v-deep table th,
.record-content ::v-deep table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 新增：为 time-info 类的单元格设置左对齐 */
.record-content ::v-deep table .time-info {
  text-align: left;
  padding-left: 16px; /* 可以根据需要调整左侧内边距 */
}

.record-content ::v-deep table th:first-child,
.record-content ::v-deep table td:first-child {
  width: 15%;
}

.record-content ::v-deep table th:not(:first-child),
.record-content ::v-deep table td:not(:first-child) {
  width: 21.25%;
}

.record-content ::v-deep table th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.record-content ::v-deep table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.record-content ::v-deep ul,
.record-content ::v-deep ol {
  margin-left: 20px;
  margin-bottom: 16px;
}

/* 添加响应式设计 */
@media (max-width: 600px) {
  .record-content ::v-deep table {
    font-size: 14px; /* 在小屏幕上减小字体大小 */
  }

  .record-content ::v-deep table th,
  .record-content ::v-deep table td {
    padding: 6px; /* 在小屏幕上减小内边距 */
  }
}
</style>
